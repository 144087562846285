<template>
  <en-table-layout :table-data="tableData.data" :span-method="spanMethod" ref="enTableLayout"
    @selection-change="handleSelectionChange" :row-key="getRowKeys">
    <template slot="toolbar">
      <el-form-item label="单据日期">
        <el-date-picker style="width: 220px" v-model="timeRange" type="daterange" align="center" size="medium"
          :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input style="width: 360px" v-model.trim="keywords" placeholder="请输入关键词" clearable>
          <el-select style="width: 130px" v-model="search_type" slot="prepend">
            <el-option label="销售单号编号" value="trade_sn"></el-option>
            <el-option label="销售人员名称" value="sell_name"></el-option>
            <el-option label="商城名称" value="shop_name"></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-button size="small" type="primary" @click="handleSearch">搜索</el-button>
      <el-button size="small" type="success" style="margin-left: auto" @click="handleSelect">确认挑选</el-button>
    </template>
    <template slot="table-columns">
      <el-table-column type="selection" :reserve-selection="true" />
      <el-table-column label="销售单据编号" width="200" prop="trade_sn" />
      <el-table-column label="所属商城" prop="shop_name" show-overflow-tooltip />
      <el-table-column label="卡号数量" prop="card_num" width="110" />
      <el-table-column label="销售人员" prop="sell_name" show-overflow-tooltip />
      <el-table-column label="单据日期" prop="create_time">
        <template slot-scope="scope">
          {{ scope.row.create_time | unixToDate }}
        </template>
      </el-table-column>
    </template>

    <el-pagination slot="pagination" @size-change="saleCardRefreshPage('handlePageSizeChange', $event)"
      @current-change="saleCardRefreshPage('handlePageCurrentChange', $event)" :current-page="tableData.page_no"
      :page-size="tableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
      :total="tableData.data_total" />
  </en-table-layout>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import * as API_SaleOrder from "@/api/saleCard";
import {
  changeDataList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "selectSaleOrderList",
  components: {
    EnTableLayout,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    shop_type: {
      type: String | Number,
      default: "",
    },
    client_name: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      search_type: "trade_sn",
      keywords: "",
      timeRange: "",
      params: {
        page_no: 1,
        page_size: 10,
        start_time: "",
        end_time: "",
      },
      tableData: {},
      multipleSelection: [],
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    spanMethod (opt) {
      return spanMethod(opt, [2, 3]);
    },
    getRowKeys (row) {
      return row.id;
    },
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.getList();
      this.$refs.enTableLayout.clearSelected();
    },
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getList();
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    getList () {
      API_SaleOrder.getCardInfoList({
        ...this.params,
        app_type_shop_type_flag: this.shop_type,
        client_name: this.client_name,
      }).then((res) => {
        this.tableData = res;
        this.tableData.data = changeDataList(res.data, "shop_trade_sell_list", ["shop_name", "card_num"]);
        this.$nextTick(() => {
          this.$refs.enTableLayout.$refs.table.doLayout();
        });
      });
    },
    handleSearch () {
      this.params = {
        page_no: this.params.page_no,
        page_size: this.params.page_size,
      };
      if (this.timeRange) {
        this.params["start_time"] = this.timeRange[0].getTime() / 1000;
        this.params["end_time"] = this.timeRange[1].getTime() / 1000 + 86399;
      }
      if (this.keywords) {
        this.params[this.search_type] = this.keywords;
      } else {
        delete this.params[this.search_type];
      }
      this.getList();
    },
    handleSelect () {
      if (!this.multipleSelection.length) {
        this.$message.error("请先选择销售单");
        return false;
      }
      API_SaleOrder.closeSelectCheckout({
        trade_sn_s: this.multipleSelection.map(item => item.trade_sn).join(",")
      }).then(res => {
        this.$emit("select", this.multipleSelection);
      })

    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .x-dialog {
  height: auto;
}
</style>
